function stickyNav() {
    const header = document.querySelector(".site-header");

    window.onscroll = function() {
        if (
            document.body.scrollTop > 100 ||
            document.documentElement.scrollTop > 100
        ) {
            header.classList.add("site-header--sticky");
        } else {
            header.classList.remove("site-header--sticky");
        }
    };
}

export default stickyNav;
