window.fabrics = () => {
    return {
        colors: [],
        brands: [],
        colorActive: true,
        brandActive: true,
        pageNumber: 1,
        size: 30,
        total: "",
        fabricData: [],
        isZoomed: false,
        zoomedImg: null,
        zoomedImgId: '',
        lastScroll: null,

        init(data) {
            // console.log(data);
            this.fabricData = data.data;
            this.total = data.meta.total;
        },

        addColor(color) {
            // We want to make the first color the only color so the input functions as a radio button
            this.colors[0] = color;

            // this.colors.push(color); // Disable array push, to rely on functionality above
            this.loadFabrics();
        },

        paginate(array) {
            const start = this.pageNumber * this.size,
                end = start + this.size;

            this.total = array.length;
            // this.fabrics = array.slice(start, end);

            return array.slice(start, end);
        },

        removeColor(color) {
            this.colors = this.colors.filter(item => item !== color);
            this.loadFabrics();
        },

        hasColor(color) {
            let value = this.colors.includes(color) ? true : false;
            return value;
        },

        containsColors(colors) {
            let test = this.colors.some(el => colors.includes(el));
            return test;
        },

        toggleColor(color) {
            this.pageNumber = 0;
            this.colors.includes(color)
                ? this.removeColor(color)
                : this.addColor(color);
        },

        toggleBrand(brand) {
            this.pageNumber = 0;
            this.brands.includes(brand)
                ? this.removeBrand(brand)
                : this.addBrand(brand);
        },

        addBrand(brand) {
            // We want to make the first color the only brand so the input functions as a radio button
            this.brands[0] = brand;

            // this.brands.push(brand); // Disable array push, to rely on functionality above
            this.loadFabrics();
        },

        removeBrand(brand) {
            this.brands = this.brands.filter(item => item !== brand);
            this.loadFabrics();
        },

        hasBrand(brand) {
            let value = this.brands.includes(brand) ? true : false;
            return value;
        },

        containsBrands(brands) {
            let test = this.brands.some(el => brands.includes(el));
            return test;
        },

        getBrands() {
            return this.brands
                .sort()
                .toString()
                .replace(/,/g, "|");
        },

        getColors() {
            return this.colors
                .sort()
                .toString()
                .replace(/,/g, "|");
        },

        toggleFilterDisplay(option) {
            option === "color"
                ? (this.colorActive = !this.colorActive)
                : (this.brandActive = !this.brandActive);
        },

        checkActive(colors, brands) {
            let activeColors =
                this.colors.length === 0 ? true : this.containsColors(colors);
            let activeBrands =
                this.brands.length === 0 ? true : this.containsBrands(brands);
            let active = activeColors && activeBrands ? true : false;
            return active;
        },

        // Create array of all pages (for loop to display page numbers)
        pages() {
            return Array.from({
                length: Math.ceil(this.total / this.size)
            });
        },

        // Next Page
        nextPage() {
            this.pageNumber++;
            this.loadFabrics();
        },

        // Previous Page
        prevPage() {
            this.pageNumber--;
            this.loadFabrics();
        },

        // Total number of pages
        pageCount() {
            return Math.ceil(this.total / this.size);
        },

        // Return the start range of the paginated results
        startResults() {
            return this.pageNumber * this.size + 1;
        },

        // Return the end range of the paginated results
        endResults() {
            let resultsOnPage = (this.pageNumber + 1) * this.size;

            if (resultsOnPage <= this.total) {
                return resultsOnPage;
            }

            return this.total;
        },

        loadFabrics() {
            let query = `/api/collections/fabrics/entries?fields=fabric_id,image,title,color,brand&limit=30&page=${this.pageNumber}`;

            if (this.colors.length) {
                query += `&filter[color:contains]=${this.getColors()}`;
            }

            if (this.brands.length) {
                query += `&filter[brand:contains]=${this.getBrands()}`;
            }

            fetch(query)
                .then(response => response.json())
                .then(data => this.init(data));
        },

        // Link to navigate to page
        viewPage(index) {
            this.pageNumber = index;
            this.loadFabrics();
        },

        // Zoom Feature
        zoomImg(e, fabricId) {
            this.zoomedImg = e.target;
            this.zoomedImgId = fabricId;
            this.lastScroll = window.pageYOffset;
  
            if (!this.isZoomed && (e.type === 'click' || e.keyCode && e.keyCode === 13)) {    
                this.zoomIn();
            } else {
                this.zoomOut();
            }
        },

        zoomIn() {
            const img = this.zoomedImg;
            this.isZoomed = true;

            // Getting Window size
            const wWidth = window.innerWidth;
            const wHeight = window.innerHeight;

            // Getting max size of Image
            let elMaxWidth = img.naturalWidth;
            let elMaxHeight = img.naturalHeight;

            // Getting Image size and position in DOM
            let elWidth = img.width;
            let elHeight = img.height;
            let elLeft = img.getBoundingClientRect().x;
            let elTop = img.getBoundingClientRect().y;

            // Calculation of max Scale Index for Width and Height - for Best Image Quality
            let sXindex = parseFloat((elMaxWidth / elWidth).toFixed(3));
            let sYindex = parseFloat((elMaxHeight / elHeight).toFixed(3));

            // Calculation of Transform Coordinates
            let tX = parseFloat((wWidth / 2 - (elWidth / 2) - elLeft).toFixed(3));
            let tY = parseFloat((wHeight / 2 - (elHeight / 2) - elTop).toFixed(3));

            img.style.zIndex = '100'; 
            img.style.willChange = 'transform'; 
            img.style.transform = `translate3d(${tX}px, ${tY}px, 0px) scale(${sXindex}, ${sYindex})`;
        },

        zoomOut() {
            const img = this.zoomedImg;

            img.style.transform = `translate3d(0px, 0px, 0px) scale(1, 1)`;
            setTimeout(() => {
                img.style.zIndex = '';
                img.style.willChange = '';
                img.style.transform = '';
            },160)

            this.isZoomed = false;
            this.zoomedImg = null;
            this.zoomedImgId = '';
            this.lastScroll = null;
        },

        scrollZoomOut() {
            let scrollTop = window.pageYOffset;
            let scrollDiff = Math.abs(this.lastScroll - scrollTop);

            if(scrollDiff >= 120) {
                this.zoomOut();
            }
        }
    };
};

export default window.fabrics;
