import MicroModal from "micromodal";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

function modal() {
    const targetElement = document.querySelector(".modal");

    // Initialize MicroModal
    MicroModal.init({
        onShow: modal => openModal(),
        onClose: modal => closeModal(),
        awaitCloseAnimation: true // Set to 'false' to remove close animation
    });

    // Remove body attribute and pause any active videos on close
    const closeModal = () => {
        enableBodyScroll(targetElement);
        document.body.removeAttribute("data-modal-active");
        const videos = document.querySelectorAll(".modal video");
        if (videos) {
            videos.forEach(video => {
                video.pause();
            });
        }
    };

    // Remove body attribute and pause any active videos on close
    const openModal = () => {
        disableBodyScroll(targetElement);
        document.body.setAttribute("data-modal-active", true);
    };

    // Play or pause video within clicked modal
    const handleVideo = (event, opened) => {
        const videoId = event.target.getAttribute("data-video-id");
        const video = document.getElementById(videoId);
        opened ? video.play() : video.pause();
    };

    // Event delegation
    document.addEventListener("click", function(event) {
        if (event.target.closest("[data-video-open]")) {
            handleVideo(event, true);
        }

        if (event.target.closest("[data-video-close]")) {
            handleVideo(event, false);
        }
    });
}

export default modal;
