/* Vendor JS */
import "./lib/modernizr.min.js";
import "alpinejs";

/* Custom JS */
import { activateLocalScrolling } from "./utilities/local-scrolling.js";
import activateTracking from "./utilities/tracking.js";
import { activateFocus } from "./utilities/focus.js";
// import invokeGTMContainer from "./utilities/gtm-container.js";
import configurator from "./utilities/configurator.js";
import fabrics from "./utilities/fabrics.js";
import "./utilities/forms.js";
import productGuide from "./utilities/product-guide.js";
import stickyNav from "./utilities/sticky-nav.js";
import navigation from "./utilities/navigation.js";
import modal from "./utilities/modal.js";
import subnavAriaCurrent from "./utilities/subnav-aria-current.js";

(function() {
    /*
     * Browser Unsupported Page
     * If we are not on the browser unsupported page already, check the site criteria
     */

    if (window.location.pathname !== "/browser-unsupported/") {
        // Perform the check based on this site’s criteria
        if (Modernizr && !Modernizr.flexbox) {
            window.location = "/browser-unsupported/";
        }
    }

    /*
     * Invoke GTM Container & Tracking
     */

    // invokeGTMContainer();
    activateTracking();

    /*
     * Activate Utilities
     */
    configurator();
    fabrics();
    activateLocalScrolling();
    activateFocus();
    stickyNav();
    navigation();
    modal();
    productGuide();
    subnavAriaCurrent();
})();
