/* globals grecaptcha, dataLayer, loadjs */
var loadjs = require("loadjs");

// This file has some yucky globally namespaced stuff which is necessary to integrate properly with RECAPTCHA. It is isolated to this file for usability.

(function() {
    /*
     * Global utility function callbacks which are called by recaptcha upon certain things happening
     * We can’t pass in any vars to these, so we stash a pending form object on the window and then the callback will pick up on that.
     */

    window.onCaptchaSuccess = () => {
        window.pendingForm.onCaptchaSuccess();
        delete window.pendingForm;
    };

    window.onCaptchaFail = () => {
        window.pendingForm.onCaptchaFail();
        delete window.pendingForm;
    };

    /*
     * Form class which manages submission events
     */

    class Form {
        constructor(el) {
            this.el = el;

            this.recaptcha = grecaptcha.render(
                el.querySelector(".form__recaptcha"),
                {
                    sitekey: "6LeAXN4ZAAAAAO-94Lg5xTpFO1sbV9-7vSV2UrLf",
                    callback: "onCaptchaSuccess",
                    "error-callback": "onCaptchaFail",
                    size: "invisible"
                }
            );

            // Add timestamp which seems to be required by Salesforce for these
            this.response = this.el.querySelector(".g-recaptcha-response");
            this.settings = this.el.querySelector(
                'input[name="captcha_settings"]'
            );

            this.timestamp();
            setInterval(() => {
                this.timestamp();
            }, 500);

            el.addEventListener("submit", ev => {
                if (!grecaptcha || !grecaptcha.getResponse(this.recaptcha)) {
                    // If the user has not already passed the recapcha...
                    ev.preventDefault();

                    window.pendingForm = this;

                    grecaptcha.execute(this.recaptcha);
                }
            });
        }

        timestamp() {
            if (this.response === null || this.response.value.trim() === "") {
                var elems = JSON.parse(this.settings.value);

                elems.ts = JSON.stringify(new Date().getTime());

                this.settings.value = JSON.stringify(elems);
            }
        }

        onCaptchaSuccess() {
            dataLayer.push({
                type: "event",
                event: "Conversion",
                event_type: "Form",
                event_action: "Submission",
                event_detail: this.el.dataset.formId,
                eventCallback: () => {
                    // After the event is submitted, submit the form.
                    this.el.submit();
                }
            });

            // After a second, if the callback hasn’t fired, just submit the form and let’s move on
            setTimeout(() => {
                this.el.submit();
            }, 1000);
        }

        onCaptchaFail() {
            dataLayer.push({
                type: "event",
                event: "Interaction",
                event_type: "Form",
                event_action: "Recaptcha Failure",
                event_detail: this.el.dataset.formId
            });

            let alert = document.createElement("dialog");

            alert.setAttribute("open", true);
            alert.innerText =
                "Sorry, CAPTCHA not recognized. If you continue to experience this issue, please email charlie@coversports.com.";

            this.el.appendChild(alert);
        }
    }

    // Render the forms. Called when the recaptcha script loads
    window.renderForms = () => {
        document.querySelectorAll(".form--recaptcha").forEach(form => {
            new Form(form);
        });
    };

    // Load the recaptcha script, after which the form script will run.
    window.onload = function() {
        setTimeout(() => {
            loadjs(
                [
                    "https://www.google.com/recaptcha/api.js?onload=renderForms&render=explicit"
                ],
                "recaptcha"
            );
        }, 500);
    };
})();
