// Helper to avoid multiple nav links from being assigned aria-current
function subnavAriaCurrent() {
    let desktopSubnavLinks = document.querySelectorAll('.site-header .site-header__subnav a'),
        mobileSubnavLinks = document.querySelectorAll('.site-header .site-mobile-nav__subnav a');

    const handleAriaCurrent = (links) => {
        if (links.length <= 1) {
            return;
        }

        links.forEach(link => {
            if (link.href ===  window.location.href) {
                link.setAttribute('aria-current', 'page');
            } else {
                link.removeAttribute('aria-current');
            }
        });
    };

    handleAriaCurrent(desktopSubnavLinks);
    handleAriaCurrent(mobileSubnavLinks);
}

export default subnavAriaCurrent;
