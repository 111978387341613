import subnavAriaCurrent from "./subnav-aria-current.js";

function activateLocalScrolling() {
    let anchorLinks = document.querySelectorAll('a[href^="#"]');

    for (let link of anchorLinks) {
        link.addEventListener("click", e => {
            let hash = link.getAttribute("href");

            scrollToHash(hash);

            e.preventDefault();
        });
    }
}

function scrollToHash(hash) {
    let target = document.querySelector(hash);
    let yOffset = 50;
    let headerHeight = document.querySelector(".site-header").offsetHeight;
    let y =
        target.getBoundingClientRect().top +
        window.pageYOffset -
        yOffset -
        headerHeight;

    if (target && y) {
        window.scrollTo({ top: y, behavior: "smooth" });
        history.pushState(null, null, hash);

        subnavAriaCurrent();

        // Attempt to focus on the target
        target.focus();

        // If the target is now in focus, great.
        // Otherwise, make it focusable.
        if (target !== document.activeElement) {
            target.setAttribute('tabindex', -1);
            target.focus();

            // After the target is unfocused, remove the tabindex attribute since focus is now on something naturally focusable
            target.onblur = () => {
                target.removeAttribute('tabindex');
            };
        }
    }
}

export { activateLocalScrolling, scrollToHash };
