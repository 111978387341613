const productGuide = () => {
    const section = document.querySelectorAll(
        ".product-guide__content__section"
    );

    const config = {
        root: document,
        rootMargin: "-125px",
        threshold: 0
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const id = entry.target.getAttribute("id");
            if (entry.intersectionRatio > 0) {
                document
                    .querySelector(`.product-guide__nav li a[href="#${id}"]`)
                    .parentElement.classList.add("product-guide__nav--active");
            } else {
                document
                    .querySelector(`.product-guide__nav li a[href="#${id}"]`)
                    .parentElement.classList.remove(
                        "product-guide__nav--active"
                    );
            }
        });
    }, config);

    section.forEach(item => {
        observer.observe(item);
    });
};

export default productGuide;
