function navigation() {
    const nav = document.querySelector(".site-header__main-nav");

    // The opened nav item
    var opened;
    var openedTrigger;

    // sets 'aria-hidden' to 'true' on opened el
    // sets 'aria-expanded' to 'false' on opened el trigger
    function reset() {
        if (opened && openedTrigger) {
            opened.setAttribute("aria-hidden", "true");
            openedTrigger.setAttribute("aria-expanded", "false");
        }
    }

    // sets 'aria-hidden' to 'false' on opened el
    // sets 'aria-expanded' to 'true' on opened el trigger
    // stores the opened list for later use
    function open(el, trigger) {
        el.setAttribute("aria-hidden", "false");
        trigger.setAttribute("aria-expanded", "true");
        // console.log(el, trigger);
        opened = el;
        openedTrigger = trigger;
    }

    // event delegation
    // reset navigation on click outside of list
    document.addEventListener("click", function(event) {
        if (!event.target.closest("[aria-hidden]")) {
            reset();
        }
    });

    function handleFocus(event) {
        reset();

        // check if a[aria-haspopup="true"] got focus
        let target = event.target;
        let hasPopup = target.getAttribute("aria-haspopup") === "true";
        let popupElement = hasPopup && document.getElementById(target.getAttribute("aria-controls"));

        if (hasPopup && popupElement) {
            open(popupElement, target);
            return;
        }

        // check if anchor inside sub menu got focus
        let anchorSubnav = target.closest(".site-header__subnav");
        let anchorSubnavTrigger = anchorSubnav && document.querySelector(`[aria-controls="${anchorSubnav.id}"]`);

        if (anchorSubnav && anchorSubnavTrigger) {
            // redefine hasPopup based on the anchorSubnavTrigger
            hasPopup = anchorSubnavTrigger.getAttribute("aria-haspopup") === "true";

            if (hasPopup) {
                open(anchorSubnav, anchorSubnavTrigger);
                return;
            }
        }
    }

    // event delegation
    nav.addEventListener("focusin", handleFocus, false);
    nav.addEventListener("focusout", reset, false);
}

export default navigation;
