window.configurator = () => {
    return {
        options: [],
        step: 1,
        size: "",
        model: "",
        sizePrice: 0,
        modelPrice: 0,
        optionsPrices: 0,

        setSize(option, price) {
            this.size = option;
            this.sizePrice = price;
            this.step++;
        },

        setModel(option, price) {
            this.model = option;
            this.modelPrice = price;
            this.step++;
        },

        navigate(direction) {
            if (direction === "back") {
                if (this.step === 3) {
                    this.options = [];
                    this.optionsPrices = 0;
                }
                this.step--;
            } else if (direction === "forward") {
                this.step++;
            }
        },

        toggleOption(option, price) {
            this.options.includes(option)
                ? this.removeOption(option, price)
                : this.addOption(option, price);
        },

        addOption(option, price) {
            this.options.push(option);
            this.optionsPrices += price;
        },

        removeOption(option, price) {
            this.options = this.options.filter(item => item !== option);
            this.optionsPrices -= price;
        },

        hasOption(option) {
            let value = this.getConfig("slug").includes(option) ? true : false;
            return value;
        },

        getConfig(query) {
            let config;
            if (query === "slug") {
                config = this.slugify(
                    this.size.toString().toLowerCase() +
                        "-" +
                        this.model.toString().toLowerCase() +
                        "-" +
                        this.options
                            .sort()
                            .toString()
                            .toLowerCase()
                );
            } else if (query == "string") {
                config = this.toTitleCase(
                    `${this.size} ${
                        this.model
                    } with options: ${this.options.join(
                        ", "
                    )}. Estimate price: $${this.getTotal()}`
                );
            }
            return config;
        },

        getTotal() {
            let total = this.sizePrice + this.modelPrice + this.optionsPrices;

            return total;
        },

        showPreviewImage(condition) {
            let conditions = condition.split("|");
            let currentConfig = this.getConfig("slug");
            return conditions.includes(currentConfig);
        },

        slugify(string) {
            const a =
                "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
            const b =
                "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
            const p = new RegExp(a.split("").join("|"), "g");

            return string
                .toString()
                .toLowerCase()
                .replace(/\s+/g, "-") // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, "-and-") // Replace & with 'and'
                .replace(/[^\w\-]+/g, "") // Remove all non-word characters
                .replace(/\-\-+/g, "-") // Replace multiple - with single -
                .replace(/^-+/, "") // Trim - from start of text
                .replace(/-+$/, ""); // Trim - from end of text
        },

        toTitleCase(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
            });
        }
    };
};

export default window.configurator;
